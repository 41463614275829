define("plutof/templates/components/tab-group/header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2KSFk8/w",
    "block": "[[[41,[30,0,[\"specificMountPoint\"]],[[[40,[[[1,\"        \"],[10,\"li\"],[15,0,[29,[\"tab-group__tab \",[52,[30,0,[\"tab\",\"selected\"]],\"tab-group__tab--active\"]]]],[15,\"data-tab-name\",[29,[[30,0,[\"tab\",\"name\"]]]]],[15,\"data-test\",[29,[[30,0,[\"tab\",\"name\"]]]]],[12],[1,\"\\n            \"],[11,3],[4,[38,2],[\"click\",[30,0,[\"switchTab\"]]],null],[12],[1,\"\\n                \"],[18,1,null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"specificMountPoint\"]]]],[]],[[[40,[[[1,\"        \"],[10,\"li\"],[15,0,[29,[\"tab-group__tab \",[52,[30,0,[\"tab\",\"selected\"]],\"tab-group__tab--active\"]]]],[15,\"data-tab-name\",[29,[[30,0,[\"tab\",\"name\"]]]]],[15,\"data-test\",[29,[[30,0,[\"tab\",\"name\"]]]]],[12],[1,\"\\n            \"],[11,3],[4,[38,2],[\"click\",[30,0,[\"switchTab\"]]],null],[12],[1,\"\\n                \"],[18,1,null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],\"%cursor:1%\",[30,0,[\"group\",\"headersContainer\"]],null]],[]]]],[\"&default\"],false,[\"if\",\"in-element\",\"on\",\"yield\"]]",
    "moduleName": "plutof/templates/components/tab-group/header.hbs",
    "isStrictMode": false
  });
});