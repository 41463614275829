define("plutof/translations/est/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    mainforms: 'Minu vormid',
    defaultValues: 'Vaikimisi väärtused',
    workgroups: 'Töörühmad',
    changePassword: 'Muuda salasõna',
    updateProfile: 'Uuenda profiili',
    oldPw: 'Praegune salasõna',
    newPw: 'Uus salasõna',
    repeatPw: 'Korda uut salasõna',
    noMatch: 'Salasõnad ei kattu.',
    clickToManage: 'Klikka töörühmade haldamiseks',
    clickToSee: 'Klikka detailvaate saamiseks',
    clickForAccess: 'Klikka ligipääsu õiguste vaatamiseks',
    join: 'Liitu',
    create: 'Lisa uus',
    manage: 'Halda',
    newUser: 'Uued kasutajad',
    name: 'Nimi',
    rights: 'Õigused',
    status: 'Staatus',
    sendRequest: 'Saada liitumissoov',
    registrationRequests: 'Registreerumistaotlused',
    approve: 'Kinnita',
    deny: 'Keeldu',
    user: 'Kasutaja',
    defaultAccess: 'Vaikimisi õigused',
    limits: 'Limiidid',
    noForms: 'Sul pole hallatavaid vorme.',
    noGroups: 'Sa pole üheski töörühmas.',
    admin: 'Haldaja',
    clearCaches: 'Tühjenda vahemälu',
    groups: {
      list: 'Sinu töörühmad',
      create: 'Lisa uus',
      join: 'Liitu',
      pending: 'Kinnitamata',
      filterMembers: 'Filtreeri liikmeid',
      addMember: 'Lisa liikmeks',
      requestsPending: '{{count}} kasutajat ootab kinnitamist',
      reason: 'Töörühmaga liitumise põhjus',
      leave: {
        button: 'Lahku töörühmast',
        title: 'Lahku töörühmast?',
        content: 'Kas olete kindel, et soovide lahkuda töörühmast {{group}}?'
      },
      email: {
        showDialog: 'E-kiri',
        title: 'Saada e-post kõikidele töörühma liikmetele.',
        subject: 'Teema',
        content: 'Teade',
        send: 'Saada'
      },
      fields: {
        user: 'Kasutaja',
        group: 'Grupp',
        rights: 'Vaatluste modereerimine',
        status: 'Staatus töörühmas',
        acceptedBy: 'Kinnitanud',
        joinedAt: 'Liitunud'
      }
    },
    classifications: {
      habitatCl: 'Vaikmisi elupaiga klassifikatsioonid',
      habitatToolT: 'Elupaiga klassifikatsioonid, mis on kasutusel PlutoFis. Mitte märgitud väljad ei ilmu elupaiga vormis.',
      taxonCl: 'Vaikimisi taksoni klassifikatsioonid',
      taxonToolT: 'Taksoni klassifikatsioonid, mis on kasutusel PlutoFis. Puu eemaldamisel ei ilmu see taksoni otsingutel.'
    },
    notifications: {
      label: 'Kasutaja teated',
      toolT: 'Saada valitud teateid e-postile.',
      systemNotifications: 'Süsteemi teated'
    },
    // TODO: Translate
    identities: {
      title: 'Identities',
      listLabel: 'Link account',
      disconnect: 'Disconnect'
    }
  };
});